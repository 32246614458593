import React, {useCallback} from 'react';
import api, {setCookie} from "../../utils/api";
import Tooltips from "../Tooltips";
import Door from "../../assets/images/door.svg";
import {useSelector} from "react-redux";
import {Roles} from "../../common/RoleFormatter";
import {useTranslation} from "react-i18next";

const GoToCabinet = ({clientId}) => {
  const {userId, right} = useSelector(state => state.auth);
  const disabledBtn = right === Roles.ROLE_SUPPORT;
  const {t} = useTranslation();
  const adminKeyURl = `${process.env.APP_URL}`;

  const onGoToCP = useCallback(() => {

    let options = {
      domain: "." + adminKeyURl,
      'max-age': -1,
      path: "/"
    }
    let options2 = {
      domain: "." + adminKeyURl,
      'max-age': -1,
      path: "/app"
    }
    let options3 = {
      domain: adminKeyURl,
      'max-age': -1,
      path: "/"
    }
    let options4 = {
      domain: adminKeyURl,
      'max-age': -1,
      path: "/app"
    }
    const cookieList =[
      "accessToken",
      "refreshToken",
      "cabinetId",
      "typeToken",
      "userId",
      "language",
      "hideStartModal",
      "openLine",
      "salesClientId"
    ]

    return () => {
      cookieList?.map((item)=>{
        setCookie(item, "", options);
        setCookie(item, "", options2);
        setCookie(item, "", options3);
        setCookie(item, "", options4);
      })
      // setCookie("accessToken", "", options);
      // setCookie("refreshToken", "", options);
      // setCookie("cabinetId", "", options);
      // setCookie("typeToken", "", options);
      // setCookie("userId", "", options);
      // setCookie("language", "", options);
      // setCookie("hideStartModal", "", options);
      // setCookie("openLine", "", options);
      api(`/clients/into-cabinet/${clientId}/${userId}`, {
        method: "POST",
        data: null,
        credentials: "include",
      })
        .then((res) => {
          res === "OK" && window.open(adminKeyURl, '_blank');
        })
    }
  }, [clientId]);

  return (
      <Tooltips
        text={t("goToCabinet")} delayShow={100} tooltipId={`door_${clientId}`} className=""
      >
        <button className={"btn btn-outline-primary ml-2 px-2"}
                hidden={disabledBtn}
                onClick={onGoToCP(clientId)}>
          <img src={Door} alt={"link to client part"}/>
        </button>
      </Tooltips>
  );
};

export default GoToCabinet;