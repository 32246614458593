import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import {FilteredTable} from "utils/FilteredTable";
import {loadTableData} from "../../redux/reducers/tableData";
import AttentiveIcon from "assets/images/attentionIcon.svg?svgr";
import {alarmLimit} from "./MCCGoogleAds";

const MccTables = ({availableMcc, columns, type = ""}) => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    availableMcc && dispatch(loadTableData("sidebarInfo"))
  }, [availableMcc]);

  return (
    <div className={"py-3"}>
      <div className="d-flex tab-header__container ">{availableMcc?.map((item, index) => {
        return <div
          className={`tab-header__item px-3 cursor-pointer d-flex align-items-center ${activeTab === index ? "active" : ""}`}
          onClick={() => setActiveTab(index)}
        >
          {item.count < alarmLimit && index !== 2 && type === "available" ?
            <AttentiveIcon className={"d-block mr-1"} width={24} height={24} fill={"#FF6600"}/> : null}
          {item.middleMcc?.tagsName}
          <span className={"badge badge-primary ml-2"}>{item.count}</span>
        </div>
      })}</div>

      <div>{availableMcc?.map((item, index) => {
        return <>{
          index === activeTab ? <>
            <div className={"py-3"}>{item.middleMcc.mccId} = {item.middleMcc.name}</div>
            <FilteredTable
              columns={columns}
              isGlobalFilter={true}
              dataName={"freeMcc"}
              dataBody={null}
              dataFromComponent={item.mcc}
            /></> : null
        }</>
      })
      }</div>
    </div>
  );
};

export default MccTables;