
export const isString = string => (typeof string === 'string' || string instanceof String);

/**
 * Shorten string to provided size
 * @param string - string to shorten
 * @param maxCharAmount - max string size
 * @returns {*}
 */
export const shortenString = (string, maxCharAmount=20) => {

  let minimumStringLength = 7,
    result = string;

  if(maxCharAmount <= 0){
    return "";
  }

  // if received string
  if(isString(string)){

    // is string is shorter than max lendth - return instantly
    if(string.length <= maxCharAmount) {
      return string;
    }

    // if max length suits for '...' insertion
    if(maxCharAmount >= (minimumStringLength)){

      if(string.length > (minimumStringLength - 1)) {
        let firstPart = string.substring(
            0,
            maxCharAmount - (minimumStringLength - 1)
          ),
          lastPart = result.substring(result.length - 3, result.length);

        return firstPart + "..." + lastPart;
      } else {
        return string.substring(0, maxCharAmount);
      }

    }
    // else - return original string
    else {
      return string.substring(0, maxCharAmount);
    }
  }

  return result;
};

